import { render, staticRenderFns } from "./cars-category-mapping.popup.vue?vue&type=template&id=6e4b254a&scoped=true&"
import script from "./cars-category-mapping.popup.vue?vue&type=script&lang=ts&"
export * from "./cars-category-mapping.popup.vue?vue&type=script&lang=ts&"
import style0 from "./cars-category-mapping.popup.vue?vue&type=style&index=0&id=6e4b254a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e4b254a",
  null
  
)

export default component.exports